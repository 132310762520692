import React from "react";
import theme from "theme";
import { Theme, Image, Box, Text, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Головна | Ласкаво просимо до Petadazen
			</title>
			<meta name={"description"} content={"Пориньте у світ морської розкоші з Petadazen. Наш флот розкішних яхт, що розташувався вздовж спокійної набережної"} />
			<meta property={"og:title"} content={"Головна | Ласкаво просимо до Petadazen"} />
			<meta property={"og:description"} content={"Пориньте у світ морської розкоші з Petadazen. Наш флот розкішних яхт, що розташувався вздовж спокійної набережної"} />
			<meta property={"og:image"} content={"https://ua.petadazen.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://ua.petadazen.com/img/4523525325.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://ua.petadazen.com/img/4523525325.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://ua.petadazen.com/img/4523525325.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://ua.petadazen.com/img/4523525325.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://ua.petadazen.com/img/4523525325.png"} />
			<meta name={"msapplication-TileImage"} content={"https://ua.petadazen.com/img/4523525325.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="150px 0 150px 0" lg-padding="80px 0 80px 0" quarkly-title="Content-14">
			<Override slot="SectionContent" flex-direction="row" />
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				justify-content="space-around"
				lg-flex-direction="column"
			>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					lg-width="100%"
					display="flex"
					flex-direction="row"
					justify-content="center"
					lg-margin="0px 0px 50px 0px"
				>
					<Image
						src="https://ua.petadazen.com/img/1.jpg"
						display="block"
						object-fit="cover"
						height="100%"
						lg-width="100%"
						lg-height="700px"
						sm-height="300px"
						margin="0px 5px 0px 0px"
						md-height="500px"
					/>
					<Image
						src="https://ua.petadazen.com/img/2.jpg"
						display="block"
						object-fit="cover"
						height="100%"
						lg-width="100%"
						lg-height="700px"
						sm-height="300px"
						margin="0px 0px 0px 5px"
						md-height="500px"
					/>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					width="50%"
					padding="0px 0px 0px 80px"
					justify-content="center"
					lg-padding="0px 0px 0px 0"
					lg-width="100%"
				>
					<Text
						as="h1"
						margin="0px 0px 20px 0px"
						font="normal 400 56px/1.2 --fontFamily-serifGaramond"
						sm-font="normal 700 42px/1.2 --fontFamily-sans"
						color="--darkL1"
						lg-margin="0px 0px 35px 0px"
						text-align="center"
					>
						Ласкаво просимо до Petadazen
					</Text>
					<Text margin="0px 0px 25px 0px" font="normal 300 18px/1.5 --fontFamily-sans" color="#474e53" text-align="center">
						Пориньте у світ морської розкоші з Petadazen. Наш флот розкішних яхт, що розташувався вздовж спокійної набережної, вабить до себе, пропонуючи не просто оренду, а ексклюзивну подорож, щоб розслабитися і дослідити відкрите море. Petadazen - це місце, де розкіш зустрічається з ніжними обіймами океану.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" flex-wrap="wrap" />
			<Box width="100%" margin="0px 0px 64px 0px" md-margin="0px 0px 30px 0px">
				<Text
					margin="0px 0px 0px 0px"
					color="--dark"
					font="--headline2"
					lg-width="100%"
					lg-text-align="center"
				>
					Відкрийте для себе елегантність Petadazen
				</Text>
			</Box>
			<Box display="flex" width="100%" md-flex-wrap="wrap">
				<Box
					width="48.5%"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-width="100%"
					padding="0px 0px 0px 0px"
					md-padding="0px 0px 0px 0px"
					md-margin="0px 0px 40px 0px"
					margin="0px 3% 0px 0px"
				>
					<Text margin="0px 0px 0px 0px" color="--darkL2" font="--base" md-text-align="center">
						Пориньте у втілення морської розкоші з Petadazen.{" "}
					</Text>
				</Box>
				<Box
					width="48.5%"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-width="100%"
					padding="0 0px 0 0px"
				>
					<Text margin="0px 0px 0px 0px" color="--darkL2" font="--base" md-text-align="center">
						Наша вишукана колекція яхт готова піднести ваш морський досвід, будь то неквапливий круїз уздовж узбережжя або особливе святкування у відкритих водах.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="0 0 0 0" inner-width="100%" quarkly-title="Stages/Steps-1">
			<Box min-width="100px" min-height="100px" display="flex" md-flex-direction="column">
				<Image
					src="https://ua.petadazen.com/img/3.jpg"
					display="block"
					width="45%"
					height="100%"
					object-fit="cover"
					md-width="100%"
					md-height="500px"
					sm-height="400px"
				/>
				<Box
					min-width="100px"
					min-height="100px"
					width="55%"
					padding="70px 70px 70px 70px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					justify-content="center"
					lg-padding="50px 50px 50px 50px"
					md-width="100%"
					md-padding="80px 50px 80px 50px"
					sm-padding="80px 25px 80px 25px"
				>
					<Text margin="0px 0px 20px 0px" font="normal 900 42px/1.2 --fontFamily-mono">
						Чому варто обрати оренду яхт Petadazen?
					</Text>
					<Box
						min-width="100px"
						min-height="100px"
						display="grid"
						grid-template-columns="repeat(2, 1fr)"
						margin="50px 0px 0px 0px"
						grid-gap="16px 24px"
						sm-grid-template-columns="1fr"
						sm-grid-template-rows="auto"
					>
						<Box
							min-width="100px"
							min-height="100px"
							padding="25px 25px 25px 25px"
							background="#ffffff"
							box-shadow="--m"
						>
							<Box
								min-height={0}
								width="50px"
								height="50px"
								color="--light"
								border-radius="50%"
								background="--color-primary"
								display="flex"
								align-items="center"
								justify-content="center"
								margin="0px 0px 15px 0px"
							>
								<Text margin="0px 0px 0px 0px" quarkly-title="1" font="normal 600 22px/1.2 --fontFamily-sansTrebuchet" transform="rotateZ(-15deg)">
									01
								</Text>
							</Box>
							<Text margin="0px 0px 0px 0px" font="normal 300 18px/1.5 --fontFamily-sansTrebuchet">
								- Розкіш на воді: Наші яхти переосмислюють поняття елегантності завдяки просторим інтер'єрам, залитим сонцем палубам і уважному екіпажу, гарантуючи розкішний досвід, який виходить за рамки буденності.
							</Text>
						</Box>
						<Box
							min-width="100px"
							min-height="100px"
							padding="25px 25px 25px 25px"
							background="#ffffff"
							box-shadow="--m"
						>
							<Box
								min-height={0}
								width="50px"
								height="50px"
								border-radius="50%"
								background="--color-primary"
								display="flex"
								color="--light"
								align-items="center"
								justify-content="center"
								margin="0px 0px 15px 0px"
							>
								<Text margin="0px 0px 0px 0px" quarkly-title="1" font="normal 600 22px/1.2 --fontFamily-sansTrebuchet" transform="rotateZ(-15deg)">
									02
								</Text>
							</Box>
							<Text margin="0px 0px 0px 0px" font="normal 300 18px/1.5 --fontFamily-sansTrebuchet">
								- Індивідуальні маршрути: Від романтичних круїзів на заході сонця до святкових заходів, Petadazen пропонує індивідуальні маршрути, розроблені відповідно до ваших побажань, гарантуючи, що кожна подорож стане персоналізованою, незабутньою подією.
							</Text>
						</Box>
						<Box
							min-width="100px"
							min-height="100px"
							padding="25px 25px 25px 25px"
							background="#ffffff"
							box-shadow="--m"
						>
							<Box
								min-height={0}
								width="50px"
								height="50px"
								border-radius="50%"
								color="--light"
								background="--color-primary"
								display="flex"
								align-items="center"
								justify-content="center"
								margin="0px 0px 15px 0px"
							>
								<Text margin="0px 0px 0px 0px" quarkly-title="1" font="normal 600 22px/1.2 --fontFamily-sansTrebuchet" transform="rotateZ(-15deg)">
									03
								</Text>
							</Box>
							<Text margin="0px 0px 0px 0px" font="normal 300 18px/1.5 --fontFamily-sansTrebuchet">
								- Гурман на палубі: Побалуйте себе кулінарними шедеврами з нашими бортовими кухарями, які створюють вишукані меню, щоб спокусити ваші смакові рецептори, поки ви насолоджуєтесь морським пейзажем.
							</Text>
						</Box>
						<Box
							min-width="100px"
							min-height="100px"
							padding="25px 25px 25px 25px"
							background="#ffffff"
							box-shadow="--m"
						>
							<Box
								min-height={0}
								width="50px"
								height="50px"
								border-radius="50%"
								background="--color-primary"
								display="flex"
								align-items="center"
								color="--light"
								justify-content="center"
								margin="0px 0px 15px 0px"
							>
								<Text margin="0px 0px 0px 0px" quarkly-title="1" font="normal 600 22px/1.2 --fontFamily-sansTrebuchet" transform="rotateZ(-15deg)">
									04
								</Text>
							</Box>
							<Text margin="0px 0px 0px 0px" font="normal 300 18px/1.5 --fontFamily-sansTrebuchet">
								- Бездоганний спокій: Наша прихильність до обслуговування найвищого рівня гарантує безперебійну, безтурботну подорож, дозволяючи вам зосередитися на створенні заповітних моментів у відкритому морі.
							</Text>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Contact />
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});